import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  label?: string
  list: ItemProps[]
  title?: string
}

export const Info = memo(function Info({ label, list, title }: Props) {
  if (!list) {
    return null
  }

  const listLeftColumn = list.slice(0, list.length / 2)
  const listRightColumn = list.slice(list.length / 2, list.length)

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Accordions row space="between" wrap>
        <Accordion>
          {listLeftColumn.length
            ? listLeftColumn.map((item, index) => (
                <Item key={index} {...item} />
              ))
            : null}
        </Accordion>
        <Accordion>
          {listRightColumn.length
            ? listRightColumn.map((item, index) => (
                <Item key={index} {...item} />
              ))
            : null}
        </Accordion>
      </Accordions>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.25rem auto;
  padding: 0 12.778vw;

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
    padding: 0 6.039vw;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
  text-align: center;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.75rem;
  }
`

const Accordions = styled(FlexBox)`
  margin-top: 3rem;
`

const Accordion = styled.div`
  width: calc(50% - 1.25rem);

  @media (max-width: 767px) {
    width: 100%;
  }
`
