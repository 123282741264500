import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  subtitle?: string
  title?: string
}

export const VisualServices = memo(function VisualServices({
  image,
  services,
  subtitle,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      {image ? (
        <LazyLoadImage className="visual-bg" effect="blur" {...image} />
      ) : null}
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        <Services dial={2} row wrap stretch>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.25rem auto 12.5rem;
  padding: 7.5rem 5.833vw 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .visual-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto 10rem;
    padding-top: 3.75rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.75rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1rem;
  }
`

const Services = styled(FlexBox)`
  transform: translateY(3.75rem);

  @media (max-width: 1023px) {
    justify-content: space-between;
  }
`
